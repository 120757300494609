export function loadEvents() {
    let burgerButton = document.getElementById('burger-button');
    let burgerIcon = burgerButton.getElementsByClassName('burger-icon')[0];
    let nav = document.getElementsByTagName("nav")[0];
    let main = document.getElementsByTagName('main')[0];
    let footer = document.getElementsByTagName("footer")[0];

    burgerButton.onclick = () =>  {
        if (nav.classList.contains("active")) {
            burgerIcon.classList.remove("active");
            nav.classList.remove("active");
            main.classList.remove("mobile-hidden");
            footer.classList.remove("mobile-hidden");
        }
        else {
            burgerIcon.classList.add("active");
            nav.classList.add("active");
            main.classList.add("mobile-hidden");
            footer.classList.add("mobile-hidden");
        }
    };
}