let testimonialsCarousel = new Splide( '#testimonials', {
    type   : 'loop',
    pagination: false,
    gap: '1em',
    padding: '0rem',
    breakpoints: {
        1024: {
            padding: '3rem'
        }
    }
});

let recipesCarousel = new Splide( '#recipes', {
    type   : 'loop',
    perPage: 4,
    perMove: 1,
    pagination: false,
    gap: '3em',
    padding: '0rem',
    breakpoints: {
        1024: {
            gap: '1em',
            padding: '2.5rem',
            perPage: 3
        },
        768: {
            gap: '0.8em',
            padding: '1.8rem',
            perPage: 2
        },
        425: {
            gap: '0.4em',
            padding: '1rem',
            perPage: 1
        }
    }
});

export function load()
{
    testimonialsCarousel.mount();
    recipesCarousel.mount();
}